import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/cancel-order": [3],
		"/contact": [4],
		"/faq": [5],
		"/order": [6],
		"/pricing": [7],
		"/privacy": [8],
		"/referral": [9],
		"/referral/[order_id]": [~10],
		"/setup": [11],
		"/setup/[userId]": [~12],
		"/success": [13],
		"/terms": [14],
		"/testing": [15],
		"/testing/addons": [16],
		"/testing/dev": [17],
		"/testing/emailer": [18],
		"/testing/member": [19],
		"/testing/product": [20],
		"/testing/quote": [21],
		"/testing/sentry-example": [22],
		"/testing/stepper": [23],
		"/testing/stripe": [24]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';